export const useSeo = (seoInput) => {
  const i18nHead = useLocaleHead()
  const setI18nParams = useSetI18nParams()

  const runtimeConfig = useRuntimeConfig()

  const seo = isRef(seoInput) ? seoInput : ref(seoInput)

  if (seo.value?.localeParams) {
    setI18nParams(seo.value?.localeParams)
  }

  return useHead({
    title: `${seo.value?.title} | ${runtimeConfig.public.siteName}` || '',
    meta: [
      ...i18nHead.value.meta,
      {
        name: 'description',
        content: `${seo.value?.description}` || '',
      },
      {
        property: 'og:image',
        content: `${seo.value?.image?.url}` || '@/assets/loader/loader.gif',
      },
      {
        property: 'og:title',
        content: `${seo.value?.title} | ${runtimeConfig.public.siteName}` || '',
      },
      {
        property: 'og:description',
        content: `${seo.value?.description}` || '',
      },
    ],
    bodyAttrs: { ...i18nHead.value.htmlAttrs },
    link: [...i18nHead.value.link],
  })
}
